import React, { useState } from 'react';
import {
  FormProvider,
  useForm
} from 'react-hook-form';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Grid,
  FormHelperText,
  Typography,
  Button,
  LinearProgress,
} from '@mui/material';
import {
  PERMITIR_FORMA_DE_PAGAMENTO_CARTAO_DE_CREDITO_PARA_INSCRICOES,
  PERMITIR_FORMA_DE_PAGAMENTO_PIX_PARA_INSCRICOES,
  DEBUG_FORM_DATA,
} from '../../constants';
import {
  FORMAS_DE_PAGAMENTO_DEFAULT_DATA,
  OPCOES_PAGAMENTO_DEFAULT_DATA,
} from '../../default-data';
import { useGetData } from "../../axiosHook/useRequestData";
import RHookFormSelect from '../../form/RHookFormSelect';
import RHookFormTextField from '../../form/RHookFormTextField';
import RHookFormCheckbox from '../../form/RHookFormCheckbox';
import pagarme from 'pagarme'
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';

const formControlStyle = {
  m: 3,
  width: "85%"
};

const formControlSpacing = {
  mt: 1,
};

const idCartaoDeCredito = FORMAS_DE_PAGAMENTO_DEFAULT_DATA["hydra:member"].find(forma => forma.cartaoDeCredito).id;
const idBoleto = FORMAS_DE_PAGAMENTO_DEFAULT_DATA["hydra:member"].find(forma => forma.boleto).id;
const firstActive = FORMAS_DE_PAGAMENTO_DEFAULT_DATA["hydra:member"].find(forma => forma.ativo).id;


const PaymentForm = (props) => {
  const { maxParcelas, session, save, setModalOpen, setModalTitle, setModalMessage, setModalClearSessionOnClose } = props;

  const defaultValues = {
    idFormaPagamento: firstActive,
    opcaoPagamento: 1,
    cardNumber: "",
    cardHolderName: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    cardCvv: "",
    afiliadoBoleto: session.id,
    ciente: false,
    concordo: false,
  };

  const [formasPagamento = FORMAS_DE_PAGAMENTO_DEFAULT_DATA] = useGetData('/formas_pagamentos');
  const [opcoesPagamento = OPCOES_PAGAMENTO_DEFAULT_DATA] = useGetData('/opcoes_pagamentos');
  const [organizador = {}] = useGetData('/organizador');

  const [isSubmitting, setIsSubmitting] = useState(false);

  // opcoesAfiliadoBoleto is an array of members that the boleto can be issued to
  // starting with the member making the entries
  // and then adding the competitors and owners of the horses in the entries
  const opcoesAfiliadoBoleto = [{ 'id': session.id, 'nome': session.nome }];
  const competitors = session.inscricoes?.reduce((acc, inscricao) => {
    acc.push({
      'id': inscricao.competidor.id,
      'nome': inscricao.competidor.nome,
    });
    return acc;
  }, []);
  if (competitors?.length > 0) {
    competitors.forEach(competitor => {
      if (!opcoesAfiliadoBoleto.find(item => item.id === competitor.id)) {
        opcoesAfiliadoBoleto.push(competitor);
      }
    })
  }
  // extract animal ids as an array from session.inscricoes
  // and fetch the owners of those animals
  const animalIds = session.inscricoes?.reduce((acc, inscricao) => {
    acc.push(inscricao.animal.id);
    return acc;
  }, []);
  const [proprietariosAnimais = []] = useGetData('/afiliados/by_animal_ids?animalIds=' + animalIds.join(','));
  if (proprietariosAnimais?.["hydra:member"]?.length > 0) {
    proprietariosAnimais["hydra:member"].forEach(proprietario => {
      if (!opcoesAfiliadoBoleto.find(item => item.id === proprietario.id)) {
        opcoesAfiliadoBoleto.push(proprietario);
      }
    })
  }
  // order alphabetically
  opcoesAfiliadoBoleto.sort((a, b) => a.nome.localeCompare(b.nome));

  const methods = useForm({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    getValues,
    // setValue,
    // setError,
    // trigger,
    control,
  } = methods;

  const idFormaPagamento = watch("idFormaPagamento");
  const afiliadoBoleto = watch("afiliadoBoleto");

  // pega os erros de validação nos campos do form e a bandeira do cartão  
  const validarCartaoPeloPagarme = campo => {
    if (idFormaPagamento !== idCartaoDeCredito) return true;
    const card = {
      card_number: getValues('cardNumber'),
      card_holder_name: getValues('cardHolderName'),
      card_expiration_date: `${getValues('cardExpirationMonth')}/${getValues('cardExpirationYear')}`,
      card_cvv: getValues('cardCvv'),
    };
    return pagarme.validate({ card: card }).card[campo];
  }
  const validarNumeroDoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_number');
  const validarNomeNoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_holder_name');
  const validarVencimentoDoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_expiration_date');
  const validarCodigoVerificadorDoCartaoPeloPagarme = () => validarCartaoPeloPagarme('card_cvv');

  const onSubmit = data => {
    // debugging:
    if (process.env.NODE_ENV !== 'production') {
      console.log(data);
    }
    // alert(JSON.stringify(data));
    setIsSubmitting(true);

    const card = {
      card_number: data.cardNumber,
      card_holder_name: data.cardHolderName,
      card_expiration_date: `${data.cardExpirationMonth}${data.cardExpirationYear}`,
      card_cvv: data.cardCvv,
    };

    // remove sensitive card information from data to be sent to server
    let dataToSend = {};
    Object.keys(data).forEach(key => (key.includes("card")) ? null : dataToSend[key] = data[key]);

    pagarme.client.connect({ encryption_key: organizador.chave })
      .then(client => client.security.encrypt(card))
      .then(card_hash => {
        dataToSend.cardHash = card_hash;
        dataToSend.inscricoes = session.inscricoes;
        dataToSend.baiasExtras = session.baiasExtras;
        dataToSend.afiliadoBoleto = afiliadoBoleto;
        // alert(JSON.stringify(dataToSend, null, 2));
        // debugging:
        if (process.env.NODE_ENV !== 'production') {
          console.log(dataToSend);
          // console.log(JSON.stringify(dataToSend));
        }
        axios
          .post(
            `/efetuarinscricoes`,
            dataToSend,
            {
              headers: {
                'Authorization': `Bearer ${session.token}`,
              },
            })
          .then((response) => {
            const data = response.data;
            console.log("response", data);
            setModalTitle(data.title);
            setModalMessage(data.paymentConfirmation);
            setModalClearSessionOnClose(false);
            setModalOpen(true);
            // reset form data  
            save({ ...session, inscricoes: [], baiasExtras: [] });
            // Note: this is here and not in a finally statement, 
            // as it was firing before axios returned
            // allowing double entries/payments to be made 
            setIsSubmitting(false);
          })
          .catch((error) => {
            console.log(error.response);
            setModalTitle("Erro");
            setModalMessage({
              type: "error",
              message: error?.response?.status === 401
                ? "Seu login expirou. Favor, faça o login novamente."
                : error.response?.data?.detail ?? error.message,
            });
            setModalClearSessionOnClose(
              error?.response?.status === 401
                ? true
                : false)
            setModalOpen(true);
            setIsSubmitting(false);
          });
      })
      .catch((error) => {
        console.log(error.response);
        setIsSubmitting(false);
      })
  };

  // debugging:
  if (process.env.NODE_ENV !== 'production') {
    console.log("formasPagamento", formasPagamento);
    console.log("opcoesPagamento", opcoesPagamento);
    console.log("organizador", organizador);
    console.log("opcoesAfiliadoBoleto", opcoesAfiliadoBoleto);
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
            <FormLabel component="legend">Pagamento</FormLabel>
            <FormGroup>
              <RHookFormSelect
                sx={formControlSpacing}
                fullWidth
                formHelperTextErrorClassName="formSelectErrorMessage"
                name="idFormaPagamento"
                label="Forma de Pagamento"
                id="idFormaPagamento"
                rules={{ required: true }}
                options={formasPagamento["hydra:member"]?.filter(forma =>
                  (forma.ativo && !forma.cartaoDeCredito && !forma.pix)
                  || (forma.cartaoDeCredito && PERMITIR_FORMA_DE_PAGAMENTO_CARTAO_DE_CREDITO_PARA_INSCRICOES)
                  || (forma.pix && PERMITIR_FORMA_DE_PAGAMENTO_PIX_PARA_INSCRICOES)).map(forma => {
                    return {
                      value: forma.id,
                      label: forma.descricao,
                    };
                  })}
                helperText=" "
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário selecionar uma forma de pagamento!",
                  }]}
              />

              {idFormaPagamento === idCartaoDeCredito &&
                <React.Fragment>

                  {maxParcelas > 1
                    ? <RHookFormSelect
                      sx={formControlSpacing}
                      fullWidth
                      formHelperTextErrorClassName="formSelectErrorMessage"
                      name="opcaoPagamento"
                      label="Parcelar"
                      rules={{ required: true }}
                      options={opcoesPagamento["hydra:member"]?.filter(opcao => opcao.numeroParcelas <= maxParcelas)?.map(opcao => {
                        return {
                          value: opcao.id,
                          label: opcao.descricao,
                        };
                      })}
                      helperText=" "
                      errorMessages={
                        [{
                          type: "required",
                          message: "É necessário selecionar uma forma de pagamento!",
                        }]}
                    />
                    : null
                  }

                  <RHookFormTextField
                    name="cardNumber"
                    key="cardNumber"
                    label="Número do cartão"
                    sx={formControlSpacing}
                    fullWidth
                    disabled={idFormaPagamento !== idCartaoDeCredito}
                    inputProps={{ maxLength: 19 }}
                    rules={{ required: true, validate: validarNumeroDoCartaoPeloPagarme }}
                    errorMessages={
                      [{
                        type: "required",
                        message: "É necessário informar o número do cartão!",
                      }, {
                        type: "validate",
                        message: "Verifique o número do cartão!",
                      }]}
                    helperText=" "
                  />

                  <RHookFormTextField
                    name="cardHolderName"
                    label="Nome no cartão"
                    key="cardHolderName"
                    id="cardHolderName"
                    sx={formControlSpacing}
                    fullWidth
                    disabled={idFormaPagamento !== idCartaoDeCredito}
                    inputProps={{ maxLength: 25 }}
                    rules={{ required: true, validate: validarNomeNoCartaoPeloPagarme }}
                    errorMessages={
                      [{
                        type: "required",
                        message: "É necessário informar o nome no cartão!",
                      }, {
                        type: "validate",
                        message: "Verifique o nome digitado!",
                      }]}
                    helperText="Digite o nome como aparece no cartão"
                  />


                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={4}>

                      <RHookFormTextField
                        name="cardExpirationMonth"
                        label="Mês"
                        sx={formControlSpacing}
                        fullWidth
                        disabled={idFormaPagamento !== idCartaoDeCredito}
                        inputProps={{ maxLength: 2 }}
                        rules={{ required: true, validate: validarVencimentoDoCartaoPeloPagarme }}
                      />

                    </Grid>
                    <Grid item xs={4}>

                      <RHookFormTextField
                        name="cardExpirationYear"
                        label="Ano"
                        sx={formControlSpacing}
                        fullWidth
                        disabled={idFormaPagamento !== idCartaoDeCredito}
                        inputProps={{ maxLength: 2 }}
                        rules={{ required: true, validate: validarVencimentoDoCartaoPeloPagarme }}
                      />

                    </Grid>
                    <Grid item xs={4}>

                      <RHookFormTextField
                        name="cardCvv"
                        label="CVV"
                        sx={formControlSpacing}
                        fullWidth
                        disabled={idFormaPagamento !== idCartaoDeCredito}
                        inputProps={{ maxLength: 4 }}
                        rules={{ required: true, validate: validarCodigoVerificadorDoCartaoPeloPagarme }}
                      />

                    </Grid>
                  </Grid>
                  <FormHelperText className={(errors?.cardExpirationMonth || errors?.cardExpirationYear || errors?.cardCvv) ? "formError" : null}>{(errors?.cardExpirationMonth?.type === "required" || errors?.cardExpirationYear?.type === "required" || errors?.cardCvv?.type === "required")
                    ? "É necessário informar o vencimento e código verificador do cartão!"
                    : (errors?.cardExpirationMonth?.type === "validate" || errors?.cardExpirationYear?.type === "validate")
                      ? "Verifique o vencimento digitado!"
                      : errors?.cardCvv?.type === "validate"
                        ? "Verifique o código digitado!"
                        : "Informe o vencimento e código verificador do cartão."}
                  </FormHelperText>
                </React.Fragment>
              }

              {idFormaPagamento === idBoleto &&
                <React.Fragment>
                  <RHookFormSelect
                    sx={formControlSpacing}
                    fullWidth
                    formHelperTextErrorClassName="formSelectErrorMessage"
                    name="afiliadoBoleto"
                    label="Em nome de"
                    rules={{ required: true }}
                    options={opcoesAfiliadoBoleto?.map(opcao => {
                      return {
                        value: opcao.id,
                        label: opcao.nome,
                      };
                    })}
                    helperText=" "
                    errorMessages={
                      [{
                        type: "required",
                        message: "É necessário selecionar em nome de quem o boleto deveria ser emitido!",
                      }]}
                  />
                  {afiliadoBoleto !== session.id &&
                    <FormGroup>
                      <Typography>Caso o boleto emitido em nome de outra pessoa não for pago, quem efetuar a inscrição será responsável pelo pagamento.</Typography>
                      <RHookFormCheckbox
                        name="ciente"
                        label="Ciente"
                        rules={{ required: "É necessário assumir essa responsabilidade" }}
                      />
                      <FormHelperText className='formSelectErrorMessage'>{errors.ciente?.message ?? " "}</FormHelperText>
                    </FormGroup>
                  }
                </React.Fragment>
              }

            </FormGroup>
          </FormControl>

          <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
            <FormGroup>
              <Typography>Ao fazer esta inscrição afirmo concordar com os termos do circular.</Typography>
              <RHookFormCheckbox
                name="concordo"
                label="Concordo"
                rules={{ required: "É necessário concordar com o regulamento" }}
              />
              <FormHelperText className='formSelectErrorMessage'>{errors.concordo?.message ?? " "}</FormHelperText>
            </FormGroup>
          </FormControl>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={(!isDirty && !isValid) || isSubmitting}
          >
            Efetuar Pagamento
          </Button>
          <LinearProgress
            className={
              isSubmitting
                ? ""
                : "hidden"
            }
            sx={{ mt: 1 }}
          />

        </form>
        {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
      </FormProvider>
    </>)
}

export default PaymentForm;